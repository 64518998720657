import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const SyntetickaKuzePage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Syntetická kůže" />
      <h1>Syntetická kůže</h1>
      <p className="mb-4 text-justify">
        Etikety ze syntetické kůže jsou variantou tisku z folie – ražby.
        Materiál je umělá kůže tloušťky 1,75mm, do které se horkým štočkem
        (raznicí) vytlačí požadovaný motiv. Vznikne tak etiketa s plastickým
        reliéfem. Na rozdíl od standardního tisku z folie se zde nepoužívá folie
        s pigmentem. Hnědá kůže je schopna po styku s horkým štočkem změnit
        barvu, ztmavne. U černé kůže změna barvy není vidět, ale o to výraznější
        je plasticita.
      </p>
      <p>Na všechny materiály máme certifikát OEKO-TEX.</p>
      <div className="justify-center flex">
        <StaticImage
          src="../images/synteticka-kuze/ukazky-1.png"
          alt="Syntetická kůže"
        />
      </div>
      <div className="justify-center flex mt-5 mb-5">
        <StaticImage
          className="w-1/2 md:w-1/4"
          src="../images/synteticka-kuze/kuze-hneda-tmava.jpg"
          alt="Syntetická kůže"
        />
      </div>
      <div className="justify-center flex my-5 ">
        <table>
          <tr>
            <td className="font-bold pr-10">Minimální množství</td>
            <td>50 ks</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Podkladový materiál</td>
            <td>
              <ul>
                <li>umělá kůže hnědá, černá ( L1 )</li>
                <li>šířky 20, 25, 30, 40, 50 mm</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td className="font-bold pr-10">Výška etikety</td>
            <td>Libovolná od 25 mm</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Cena</td>
            <td>Cena je závislá na velikosti etikety a objednaném množství</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Dodací lhůta</td>
            <td>1 - 2 týdny od potvrzení objednávky</td>
          </tr>
        </table>
      </div>
      <p>
        Poptávky a jakékoli dotazy či připomínky ohledně etiket zasílejte na:{" "}
        <a className="font-bold" href="mailto:vyroba@etka.cz">
          tistene@etka.cz
        </a>
      </p>
    </Layout>
  );
};

export default SyntetickaKuzePage;
